.popup{
    height: 80vh;
    height: -webkit-fill-available;
    // height: calc(100vh - env(safe-area-inset-bottom))
}

.panel{
    position: relative;
    height: 35%;
}

.search{
    background-color: #fff;
    padding: 15px;
    position: absolute;
    width: 100%;
    top: -62px;
    z-index: 2;
}


.topBar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 401;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.map{
    // height: 65vh;
    height: 65%;
    position: relative;
}

.pin{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 401;
}

.select{
    color: green;
    font-size: 18px;
    width: 20px;
}


.listItem {
    :global(.adm-list-item-content-main) {
        flex: 0 0 90%;
        min-width: 0;
    }
    :global(.adm-list-item-content-extra) {
        flex: 0 0 25px;
    }
}
