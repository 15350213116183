@import '~flag-icon-css/css/flag-icon.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
    display: inline-block;
}
html {
    min-height: 100vh;
    height: -webkit-fill-available;
}
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}
#root {
    height: 100%;
    width: 100%;
}
:root {
    --adm-color-primary: #2445CD;
    --adm-color-success: #2AC769;
    --adm-color-warning: #F6A609;
    --adm-color-danger: #FB4E4E;
    --adm-color-white: #ffffff;
    --adm-color-weak: #999999;
    --adm-color-light: #cccccc;
    --adm-border-color: #eeeeee;
    --adm-font-size-main: 13px;
    --adm-color-text: #333333;
    --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

@layer components {
  .btn-sm {}
  .btn-md {}
  .btn-lg {
      @apply h-12
  }
  .btn-xl {}
  .btn-primary {
    @apply bg-primary-1000 text-white font-semibold rounded-lg shadow-lg hover:bg-primary-800;
  }
  .btn-success {
    @apply bg-success-default text-white font-semibold rounded-lg shadow-lg hover:bg-success-light;
  }
  .btn-error {
    @apply bg-error-default text-white font-semibold rounded-lg shadow-lg hover:bg-error-light;
  }
  .btn-warning {
    @apply bg-warning-default text-white font-semibold rounded-lg shadow-lg hover:bg-warning-light;
  }
}
