.square{
    position: relative;
}
.placeholder{
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}
.content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
